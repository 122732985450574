<template>
  <v-container fluid class="fluid">
    <h1 class="text-left" v-html="$t('application_users_header')"></h1>

    <v-form ref="createApplicationUserForm" @submit.prevent="onSubmit()" id="createApplicationUserForm">
      <v-row>
        <v-col cols="12" xs="12" md="4" xl="4">
          <v-text-field
            id="name"
            :label="$t('application_users_username')"
            v-model="applicationUser.name"
            :rules="[$rules.required]"
            @keydown.enter.prevent
          ></v-text-field>

          <v-text-field id="email" :label="$t('application_users_agate_email')" v-model="applicationUser.email" @keydown.enter.prevent></v-text-field>

          <v-text-field id="agateNr" :label="$t('application_users_agate_nr')" v-model="applicationUser.agateId" :rules="[$rules.required, $rules.length7]" />

          <v-text-field
            id="agateIntegrationId"
            :label="$t('application_users_agate_integration_id')"
            v-model="applicationUser.agateIntegrationId"
            :rules="[$rules.isNumeric]"
          />

          <v-text-field id="person_last_name" :label="$t('application_users_last_name')" v-model="applicationUser.person.lastName" :rules="[$rules.required]" />

          <v-text-field id="person_first_name" :label="$t('application_users_first_name')" v-model="applicationUser.person.firstName" />

          <v-text-field
            type="text"
            id="initials"
            :label="$t('person_initials')"
            v-model="applicationUser.person.initials"
            @keydown.enter.prevent
          ></v-text-field>

          <v-text-field id="person_cantonal_id" :label="$t('application_users_cantonal_id')" v-model="applicationUser.person.cantonalId" />

          <v-switch id="application_users_active" v-model="applicationUser.active" color="primary" :label="$t('application_users_active')"></v-switch>

          <v-btn class="m-2" color="primary" :loading="loading" type="submit"><span v-html="$t('application_users_create')" /></v-btn>
          <v-btn class="m-2" color="secondary" :to="{ name: 'applicationUsers_read' }" type="reset"><span v-html="$t('application_users_cancel')" /></v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script lang="ts">
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'applicationUsersCreate',

  data() {
    return {
      applicationUser: {
        name: undefined,
        agateId: undefined,
        agateIntegrationId: undefined,
        email: undefined,
        initials: undefined,
        person: {
          firstName: undefined,
          lastName: undefined,
          cantonalId: undefined,
          initials: undefined
        }
      },
      loading: false
    }
  },
  methods: {
    async onSubmit() {
      if (!(await this.$refs.createApplicationUserForm.validate()).valid) {
        return false
      }
      try {
        this.loading = true
        if (this.applicationUser.person.initials === '' || this.applicationUser.person.initials === undefined) {
          const firstInitial = this.applicationUser.person.firstName ? this.applicationUser.person.firstName[0] : ''
          const lastInitial = this.applicationUser.person.lastName ? this.applicationUser.person.lastName[0] : ''
          this.applicationUser.person.initials = `${firstInitial}${lastInitial}`
        }
        const result = await this.axios.post(apiURL + '/applicationUsers', { ...this.applicationUser }, { headers: { 'Content-Type': 'application/json' } })

        if (await result) {
          this.$router
            .push({
              name: 'applicationUsers_edit',
              params: { id: result.data.id }
            })
            .catch('Pfad konnte nicht gefunden werden')
        }
      } catch (e) {
        emitter.emit('toastError', {
          message: this.$t('application_users_toast_error')
        })
        showError(e)
      } finally {
        this.loading = false
      }
    }
  }
})
</script>
